@let balance = balance$ | async;

@if (balance) {
  <div class="my-balance__item">
    <span class="my-balance__label">{{ 'header.balance.available' | translate }}</span>
    <span class="my-balance__value my-balance__value_success">{{ balance.available | currency: 'USD' }}</span>
  </div>

  <div class="my-balance__item">
    <span class="my-balance__label">{{ 'header.balance.onHold' | translate }}</span>
    <span class="my-balance__value my-balance__value_warning">{{ balance.onHold | currency: 'USD' }}</span>
  </div>
}
