import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AsyncPipe } from '@angular/common';

import {
  ButtonComponent,
  CellComponent,
  IconComponent,
  IconName,
  RowDirective,
  TableColumn,
  TableComponent,
  TableRowAction,
  TableWrapperComponent,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { Entity, PaginationRequest } from '@rp/shared/models';
import { CommonMediaQueries, MediaScreenService } from '@rp/shared/services';
import { Observable } from 'rxjs';
import { ReplaceEmptyPipe, TransformStringListPipe } from '@rp/shared/pipes';

import { OffersTableFiltersComponent } from '../offers-table-filters/offers-table-filters.component';
import { OffersItem, Offer, OffersRes } from '../../../../models';
import { OffersWebmasterMobileTableComponent } from '../offers-webmaster-mobile-table';

@Component({
  selector: 'rp-offers-table',
  standalone: true,
  templateUrl: './offers-table.component.html',
  styleUrl: './offers-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CellComponent,
    IconComponent,
    RowDirective,
    TableComponent,
    TableWrapperComponent,
    TranslateModule,
    OffersTableFiltersComponent,
    OffersWebmasterMobileTableComponent,
    TransformStringListPipe,
    ReplaceEmptyPipe,
  ],
})
export class OffersTableComponent implements OnInit {
  isDesktop$: Observable<boolean>;
  isMobile = false;
  icons = IconName;
  offersData = input.required<OffersRes>();

  columns = input<TableColumn[]>(null);
  isLoading = input<boolean, boolean>(false, {
    transform: booleanAttribute,
  });
  trafficSources = input<Entity[]>(null);
  verticals = input<Entity[]>(null);
  geos = input<Entity[]>(null);

  applyFilters = output<void>();
  onSort = output<Sort>();
  onAction = output<TableRowAction<OffersItem>>();
  onSelect = output<Offer>();
  onPagination = output<PaginationRequest>();
  resetFilters = output();

  private readonly _mediaScreenService = inject(MediaScreenService);

  ngOnInit(): void {
    this.isDesktop$ = this._mediaScreenService.mediaMatcher(CommonMediaQueries.XL);
    this.isMobile = this._mediaScreenService.isMatched(CommonMediaQueries.XL, true);
  }
}
