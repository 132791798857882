import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Languages, MENU_CLOSE_TIMEOUT } from '@rp/shared/models';
import { IconComponent, IconName } from '@rp/shared/components';
import { environment } from '@rp/environments';
import { LanguageService } from '@rp/shared/services';
import { fromEvent } from 'rxjs';

import { CountriesService } from '../../services/countries.service';

@Component({
  selector: 'rp-languages',
  standalone: true,
  templateUrl: './languages.component.html',
  styleUrl: './languages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenu, MatMenuTrigger, IconComponent, MatMenuItem],
})
export class LanguagesComponent {
  menuTrigger = viewChild(MatMenuTrigger);

  languages = signal<Languages[]>(environment.languages);
  currentLanguage = signal<Languages>(null);

  languageChanged = output<Languages>();

  flagIcon = computed(() => {
    return this.icons[('Flag' + this.currentLanguage()) as keyof typeof IconName];
  });
  filteredLanguages = computed<{ icon: string; language: Languages }[]>(() => {
    const filteredLanguages = [];

    for (const language of this.languages()) {
      if (language !== this.currentLanguage()) {
        filteredLanguages.push({
          icon: this.icons[('Flag' + language) as keyof typeof IconName],
          language,
        });
      }
    }

    return filteredLanguages;
  });

  icons = IconName;

  private _timeOut: ReturnType<typeof setTimeout>;

  private _languageService = inject(LanguageService);
  private _destroyRef = inject(DestroyRef);
  private readonly _countriesService = inject(CountriesService);

  constructor() {
    effect(() => {
      this._countriesService.registerLocale(this.currentLanguage());
    });

    this._setCurrentLang();
    this._subscribeToDocumentClick();
  }

  handleLanguageChange(language: Languages): void {
    if (this._timeOut) {
      clearTimeout(this._timeOut);
    }

    /** setTimeout is used to prevent the language change from
     *  being applied before the menu close animation ends */
    this._timeOut = setTimeout(() => {
      this.currentLanguage.set(language);

      this.languageChanged.emit(language);
    }, MENU_CLOSE_TIMEOUT);

    this._languageService
      .setTranslation(language)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this._languageService.setLanguage(language);
      });
  }

  onMenuTriggerClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  private _setCurrentLang(): void {
    this.currentLanguage.set(this._languageService.getCurrentLanguage());
  }

  private _subscribeToDocumentClick(): void {
    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        if (this.menuTrigger().menuOpen) {
          this.menuTrigger().closeMenu();
        }
      });
  }
}
