<ng-container *rpSpinner="isLoading()">
  <section class="title">{{ 'general.description' | translate }}</section>

  <section class="logo">
    <div class="logo__image">
      @if (offerInfo().photoUri) {
        <img class="logo__image" [src]="offerInfo().photoUri" [alt]="'offers.info.descriptions.logo' | translate" />
      } @else {
        <rp-icon class="logo__image" [iconName]="icons.Avatar" size="l" />
      }
    </div>
    <div class="logo__details">
      @if (isMobile) {
        <div class="logo__details-mobile">
          @if (offerInfo().id) {
            <div class="details__value">
              <span class="details__label">{{ 'general.id' | translate }}</span>
              <span>{{ offerInfo().id }}</span>
            </div>
          }
          <div>
            <span class="name-mobile details__label">{{ 'general.name' | translate }}</span>
            <span class="details__value">{{ offerInfo().name | replaceEmpty }}</span>
            @if (offerInfo().isExclusive) {
              <rp-icon [iconName]="icons.Exclusive" />
            }
          </div>
        </div>
      } @else {
        <div class="details">
          @if (offerInfo().id) {
            <div class="details__value">
              <span class="details__label">{{ 'offers.info.descriptions.id' | translate: { id: offerInfo().id } }}</span>
            </div>
          }
          <div class="size-24 details__value">
            {{ 'offers.info.descriptions.name' | translate: { name: offerInfo().name } | replaceEmpty }}
            @if (offerInfo().isExclusive) {
              <rp-icon [iconName]="icons.Exclusive" />
            }
          </div>
        </div>
      }
    </div>
  </section>
  <section class="descriptions">
    @if (offerInfo().description) {
      <div class="row">
        <div class="details">
          <span class="details__label">{{ 'offers.info.descriptions.descriptionTitle' | translate }}</span>
          <span class="details__value">{{ offerInfo().description }}</span>
        </div>
      </div>
    }
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'general.vertical' | translate }}</span>
        <span class="details__value">{{ offerInfo().verticals | transformStringList: ', ' | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'general.sources' | translate }}</span>
        <span class="details__value">{{ offerInfo().trafficSources | transformStringList: ', ' | replaceEmpty }}</span>
      </div>
    </div>

    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'general.model' | translate }}</span>
        <span class="details__value">{{ offerInfo().paymentModel.name | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'general.geo' | translate }}</span>
        <span class="details__value">{{ offerInfo().geos?.join() | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'general.cpa' | translate }}</span>
        <span class="details__value">{{ offerInfo().cpaRate | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'general.revshare' | translate }}</span>
        <span class="details__value">{{ offerInfo().revsharePercent | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'general.events' | translate }}</span>
        <span class="details__value">{{ offerInfo().events | transformStringList: ', ' | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.targetAction' | translate }}</span>
        <span class="details__value">{{ offerInfo().targetAction.name | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.kpiSoft' | translate }}</span>
        <span class="details__value">{{ offerInfo().kpiSoft | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.kpiHard' | translate }}</span>
        <span class="details__value">{{ offerInfo().kpiHard | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.cap' | translate }}</span>
        <span class="details__value">{{ offerInfo().capCount | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.holdPeriodDays' | translate }}</span>
        <span class="details__value">{{ offerInfo().holdPeriodDays | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.licences' | translate }}</span>
        <span class="details__value"> {{ offerInfo().licenses?.length ? offerInfo().licenses : '-' }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.postbackDelay' | translate }} </span>
        <span class="details__value">{{ offerInfo().postbackDelay | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.targetAudience' | translate }}</span>
        <span class="details__value">{{ offerInfo().targetAudience | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.availablePaymentMethods' | translate }}</span>
        <span class="details__value">{{ offerInfo().availablePaymentMethods | replaceEmpty }}</span>
      </div>
    </div>

    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.registrationFieldCount' | translate }}</span>
        <span class="details__value">{{ offerInfo().registrationFieldCount | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.topSlots' | translate }}</span>
        <span class="details__value">{{ offerInfo().topSlots | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.availableLanguages' | translate }}</span>
        <span class="details__value">{{ offerInfo().availableLanguages | transformStringList: ', ' | replaceEmpty }}</span>
      </div>

      <div class="details">
        <span class="details__label">{{ 'general.bonuses' | translate }}</span>
        <span class="details__value">{{ offerInfo().bonus | replaceEmpty }}</span>
      </div>
    </div>
    <div class="row">
      <div class="details">
        <span class="details__label">{{ 'offers.info.descriptions.recommendation' | translate }}</span>
        <span class="details__value">{{ offerInfo().recommendation | replaceEmpty }}</span>
      </div>
    </div>
  </section>
</ng-container>
